import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import YoutubeVideo from 'components/Web_User_Interface/720p_Series/Network/Push_Service/VideoInstarVision';
import PushTable from 'components/Web_User_Interface/720p_Series/Network/Push_Service/pushTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Network Menu // Push Service",
  "path": "/Web_User_Interface/720p_Series/Network/Push_Service/",
  "dateChanged": "2017-12-08",
  "author": "Mike Polinowski",
  "excerpt": "Your camera can notify you by Email every time a motion detection is triggered. A push notification serves the same purpose but is a lot faster! Activate Push if you want to be notified right away when something is happening in front of your camera. The push notification only works with our InstarVision app for iOS or Android devices.",
  "image": "./WebUI_720p_SearchThumb_Network_Push.png",
  "social": "/images/Search/WebUI_720p_SearchThumb_Network_Push.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "720p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Network Menu // Push Service' dateChanged='2017-12-08' author='Mike Polinowski' tag='INSTAR IP Camera' description='Your camera can notify you by Email every time a motion detection is triggered. A push notification serves the same purpose but is a lot faster! Activate Push if you want to be notified right away when something is happening in front of your camera. The push notification only works with our InstarVision app for iOS or Android devices.' image='/images/Search/WebUI_720p_SearchThumb_Network_Push.png' twitter='/images/Search/WebUI_720p_SearchThumb_Network_Push.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/720p_Serie/Netzwerk/Push_Dienst/' locationFR='/fr/Web_User_Interface/720p_Series/Network/Push_Service/' crumbLabel="Push Service" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "720p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#720p-web-user-interface",
        "aria-label": "720p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`720p Web User Interface`}</h1>
    <h2 {...{
      "id": "network-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#network-menu",
        "aria-label": "network menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Network Menu`}</h2>
    <h3 {...{
      "id": "push-service",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#push-service",
        "aria-label": "push service permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Push Service`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <blockquote>
      <p parentName="blockquote">{`Your camera can notify you by Email every time a motion detection is triggered. A push notification serves the same purpose but is a lot faster! Activate Push if you want to be notified right away when something is happening in front of your camera. The push notification works with our InstarVision app for `}<a to="/Software/iOS/InstarVision/iPhone/Multiview/">{`iPhone`}</a>{`, `}<a to="/Software/iOS/InstarVision/iPad/Multiview/">{`iPad`}</a>{`, `}<a to="/Software/Android/InstarVision/Multiview/">{`Android`}</a>{`, `}<a to="/Software/Windows/InstarVision/Windows_Phone/Multiview/">{`Windows Phone`}</a>{` or `}<a to="/Software/Windows/InstarVision/Metro/Multiview/">{`Windows`}</a>{` devices.`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "716px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/57f0499bce6cd40f20a2378146bc3440/6bbf7/WebUI-Network_Push.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "104.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAACXBIWXMAAAsSAAALEgHS3X78AAADWElEQVQ4y51USzObYRTOb7H3C3TalZ2VjXYsjYUZCws7jLFhRscY0qowDYK4NcSofu7EZUjyhZAE+UgiTeRCxHVYPp3ntF8ro+1UM3Pyvt97znne59xewyebDW9KS/Hi5SuUvH6Dj2Yz+vr6MGS1ore3Fz09PeizWDAwMCB7i8UCk8mED52dohcbsxnd3d3o6uqCYWrqs2yam9+KcmdnB9seD1TVg+3tbbjdKpxOJ3Z3d6FpGoLBIA4PD7G/v49oNAq73Y7Kykqsra3B4XDAYLVa5XYyXVlZQSQSQSDgh8/nF+e9vT14d7wIRyLIZrO4uLjIkbOzMwwNDUFVVSFgYBhtbW3o7+/HzMwMYrEYUskUvka/InoSRSwWx0U2i0wmI86UdDqNVColZxSydLlccLvdMKyurmJubg5cvV4vkskELi8vkUwmEQ6FoR1q0IKarKHjYxxpx/LNiwlGluPj4wJGloZkIoGzdBpXV1cCRAYMLfuD1fn5OTLnGdFTx+90KvXT5gngaTwuxvw9PDxIKDTUWR4cHEDTgpJ05vfo6AiJROLPgMoXBR3vO9BlMkFRvggQjcju5uYG5eXlyM/PR1NTE9rb21FcXIzZ2VnR6YXJARwZGYHR+A6Dg4NQFEVY0YgOgUAAZWVlaGhoQGNjI6qrq1FRUSHV/CMgG9VsNkvpWeV4PC6h3N/fY3l5GQUFBaipqUFJSYmwbG1tlV5lmn4LSGYEHR0dxfT0NE5PTwXw7u4OHo8HeXl5qKqqQm1tLVpaWiRsOj7uyRxA9iEnZcJuh6L8Ynh9fS2TQWb19fUoKipCYWGh7Dc2NnB7e/uzD3MAx8bGMDw8LNNCQLYRwyFDVphhMofU19XVwWg0yuixeLTj5TmA/OPIcQ6ZbIbMGQ2FQrKyjSgnJyeyckq4D4fDYkP7iYkJmRQBXFxclDZghRkKDTj4Pp9PhI8ChX1IJ/3c7/fLnLM39dETQKfLBYIuLS3Bo3qkbfSpeSzM6e/OnxSFN8/Pz2NhYRFbW1syXrrzvwhBbTbbL0BuVh0OCZc5nJycBJ80Foq9+TfRi8m2+/52ur8zZANT+JCur69LgZ4j9CM7AeRGdauSPx7wteZtzxEdTEJ2udxwbjnlqd/c3BTF/4jO8BvUnXWFYO9UewAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/57f0499bce6cd40f20a2378146bc3440/e4706/WebUI-Network_Push.avif 230w", "/en/static/57f0499bce6cd40f20a2378146bc3440/d1af7/WebUI-Network_Push.avif 460w", "/en/static/57f0499bce6cd40f20a2378146bc3440/04ac8/WebUI-Network_Push.avif 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/57f0499bce6cd40f20a2378146bc3440/a0b58/WebUI-Network_Push.webp 230w", "/en/static/57f0499bce6cd40f20a2378146bc3440/bc10c/WebUI-Network_Push.webp 460w", "/en/static/57f0499bce6cd40f20a2378146bc3440/d8378/WebUI-Network_Push.webp 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/57f0499bce6cd40f20a2378146bc3440/81c8e/WebUI-Network_Push.png 230w", "/en/static/57f0499bce6cd40f20a2378146bc3440/08a84/WebUI-Network_Push.png 460w", "/en/static/57f0499bce6cd40f20a2378146bc3440/6bbf7/WebUI-Network_Push.png 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/57f0499bce6cd40f20a2378146bc3440/6bbf7/WebUI-Network_Push.png",
              "alt": "Web User Interface - 720p Series - Network Push Service",
              "title": "Web User Interface - 720p Series - Network Push Service",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <PushTable mdxType="PushTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <EuiSpacer mdxType="EuiSpacer" />
    <YoutubeVideo mdxType="YoutubeVideo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      